import nl from './locales/nl.json'

export default defineI18nConfig(() => ({
  defaultLocale: 'nl',
  detectBrowserLanguage: false,
  legacy: false,
  locale: 'nl',
  messages: { nl },
  warnHtmlMessage: false
}))
